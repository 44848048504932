/* eslint-disable max-len */
import HeaderIntro from '@components/HeaderIntro';
import { PageErrorInterface } from '@interface/components/PageError';
import { Box, Button, Container, Text } from '@src';
import { theme } from '@theme';
import styled from 'styled-components';

export const TextItalic  = styled.span`
  font-style: Italic;
  font-weight: 600;
`;

export const TextBoldLine  = styled.span`
  text-decoration: underline;
  font-weight: 600;
  color:#2C3143;
`;

const PageError = (props: PageErrorInterface) => {
  return (
    <Container>
      {!props.integration &&
      <HeaderIntro
        theme={theme}
        backGroundColor="tranparent" /> }
      <Box
        styleSheet={{
          justifyContent: 'center',
          alignItems: 'center'
        }}>
        <Box
          styleSheet={{
            flex: 'none',
            height: '0 auto',
            width: '460px',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            boxSshadow: '0px 4px 50px rgba(0, 0, 0, 0.08)',
            borderRadius: '10px',
            padding: '40px'
          }}>
          <Text msgError>{props.title}</Text>

          {props.error === 404 &&
            <><Text dataCy="messageError"
              styleSheet={{
                fontFamily: 'Lora',
                fontWeight: '400',
                fontSize: '20px',
                lineHeight: '25px',
                color: '#2C3143',
                margin: '8px 0 24px 0',
              }}
            >
              {!props.subTitle && <>Página <TextItalic>&nbsp;não encontrada!</TextItalic></>}
              {props.subTitle && <><TextItalic>{props.subTitle}</TextItalic></>}
            </Text>
            </>
          }

          {props.error === 500 &&
            <><Text
              styleSheet={{
                fontFamily: 'Lora',
                fontWeight: '400',
                fontSize: '20px',
                lineHeight: '25px',
                color: '#2C3143',
                margin: '8px 0 24px 0',
              }}
            >
              {!props.subTitle && <>Página Esta Página <TextItalic>&nbsp;não está funcionando!</TextItalic></>}
              {props.subTitle && <><TextItalic>{props.subTitle}</TextItalic></>}
            </Text>
            </>
          }

          {props.error === 422 &&
            <><Text
              styleSheet={{
                fontFamily: 'Lora',
                fontWeight: '400',
                fontSize: '20px',
                lineHeight: '25px',
                color: '#2C3143',
                margin: '8px 0 24px 0',
              }}
            >
              está demorando mais que o previsto...
            </Text>
            </>
          }

          {props.error &&
            <>
              <img
                src={`/imgs/${props.error}.png`}
                alt={`Error ${props.error}`}/>
              {props.error !== 422 && !props.noCodeError &&
                  <Text numberError>Erro {props.error}</Text>
              }
            </>
          }

          <Text
            styleSheet={{
              textAlign: 'center',
              color: '#929AB2',
              fontFamily: 'Barlow',
              display:'block'
            }}>

            {props.textError}
            {props.error === 500 &&
              <>Tente atualizar a página em alguns instantes ou <TextBoldLine>reporte o problema ao nosso suporte.</TextBoldLine></>
            }
            {(props.error === 404 || props.error === 422) && !props.noCodeError &&
              <>Para mais informações entre em <TextBoldLine>contato com nosso suporte</TextBoldLine> ou acesse nossa home page.</>
            }
          </Text>

        </Box>
        <Button
          disabled={props.disabled}
          main={'primary'}
          styleSheet={{
            marginTop: '25px',
            padding: 0
          }}
          onClick={props.click}
          label={props.buttonLabel} />
      </Box>
    </Container>
  );
};

export default PageError;
