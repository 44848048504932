import PageError from '@components/PageError';
import { OfflineContext } from '@context/OfflineContext';
import httpCodes from '@utils/httpCodes';
import { useRouter } from 'next/router';
import { useContext } from 'react';

const Error = () => {
  const { offline } = useContext(OfflineContext);
  const router = useRouter();
  return (
    <PageError
      disabled={offline ? true : false}
      title={'Ops, alguma coisa deu errado...'}
      error={httpCodes.notFound}
      textError='Não conseguimos encontrar a página que você procura. '
      buttonLabel='Acessar Home Page'
      click={()=>{
        if (!offline){
          router.push({
            pathname: '/',
            query: router.query
          });
        }
      }}/>
  );
};

export default Error;
