/* eslint-disable react/prop-types */
import { ThemeInterface } from '@interface/theme';
import { Header as StyledHeader } from '@src';
import { theme as defaultTheme } from '@theme';

interface HeaderProps{
  theme: ThemeInterface;
  backGroundColor?: string;
}

export default function Header({ theme, backGroundColor }: HeaderProps) {
  const headerTheme = theme ?  theme : defaultTheme;

  return <StyledHeader
    theme={headerTheme}
    backGroundColor={backGroundColor}
    centerContent={(
      <img
        onClick={()=>{
          window.open(
            'https://www.serenacare.com.br/',
            '_blank',
            'noopener,noreferrer');
        }}
        src='/imgs/logo-dark.svg'
        width="110" height="18"
        alt='Logo serena'/>
    )}/>;
}
